import * as React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/Layout';

export const query = graphql`
  query NotFound {
    file(relativePath: { eq: "404.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const NotFoundPage = ({ data }: any) => {
  return (
    <Layout>
      <p className="text-center fw-bold aqua-color">Page not found</p>
      <Img
        fluid={data.file.childImageSharp.fluid}
        className="img-not-found img-floating"
      />
    </Layout>
  );
};

export default NotFoundPage;
